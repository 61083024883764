<template>
  <vbt-dialog
    v-model="dialog"
    title="Test Estimation"
    width="70%"
    @close="resetForm"
  >
    <template #activator="{ on }">
      <v-btn
        x-small
        color="success"
        v-on="on"
      >
        Test estimation
      </v-btn>
    </template>

    <v-layout column>
      <vbt-code
        v-model="testCodeBody"
        max-height="500px"
      />

      <v-layout
        v-if="isInvalidJSON"
        class="mt-2"
        justify-end
      >
        <span
          class="error--text"
        >
          Invalid JSON
        </span>
      </v-layout>
    </v-layout>

    <template #footer>
      <v-layout
        class="pl-2"
        align-center
      >
        <v-flex sm4>
          <v-text-field
            v-model.trim="orderId"
            placeholder="Fill for testing by order"
            class="vbt-hide-form-control-details"
            label="Order ID"
            name="orderId"
            clearable
            dense
            @keypress.enter="runTest(true)"
          />
        </v-flex>

        <v-btn
          :disabled="!orderId"
          color="success"
          class="ml-2"
          small
          @click="runTest(true)"
        >
          Test by order id
        </v-btn>

        <v-spacer />

        <v-btn
          :disabled="disableSubmitJSON"
          color="success"
          small
          @click="runTest(false)"
        >
          Test JSON
        </v-btn>

        <v-btn
          class="ml-2"
          small
          text
          @click="dialog = false"
        >
          Close
        </v-btn>
      </v-layout>
    </template>
  </vbt-dialog>
</template>

<script>
import HTTP from '@api';

import { wrapToLoadingFn } from '@helpers';

export default {
  name: 'EstimationTest',

  data() {
    return {
      dialog: false,

      testCodeBody: '',
      orderId: '',
    };
  },

  computed: {
    isEmptyBody() {
      return !this.testCodeBody.trim();
    },

    isInvalidJSON() {
      if (this.isEmptyBody) {
        return false;
      }

      try {
        JSON.parse(this.testCodeBody);
        return false;
      } catch (e) {
        return true;
      }
    },

    disableSubmitJSON() {
      return this.isEmptyBody || this.isInvalidJSON;
    },

    parsedTestCodeBody() {
      try {
        return JSON.parse(this.testCodeBody);
      } catch (e) {
        return {};
      }
    },
  },

  methods: {
    runTest(byOrderId) {
      const url = byOrderId
        ? `shipping-estimations/test/order/${this.orderId}`
        : 'shipping-estimations/test';

      this.$VBlackerTheme.alert.warning({
        text: 'Run test?',
      }, () => this.wrapToLoadingFn({
        req: HTTP.post.bind({}, url, this.parsedTestCodeBody),
        loadingSpinnerType: 'fullscreen',
        onSuccess: ({ data: { id = '' } = {} }) => {
          if (!id) {
            this.$VBlackerTheme.notification.warning('Empty result');
            return;
          }

          this.$VBlackerTheme.notification.success('Test success');

          this.$router.push({ name: 'estimation', params: { id } });
        },
      }));
    },

    resetForm() {
      this.testCodeBody = '';
      this.orderId = '';
    },

    wrapToLoadingFn,
  },
};
</script>
