var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-blacker-layout',{attrs:{"column":""}},[_c('v-blacker-search',{attrs:{"items":_vm.searchFieldsConfig},on:{"reset":_vm.onResetSearch,"search":_vm.onSearch},model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('vbt-content-box',{attrs:{"loading-error":_vm.loadingError,"loading":_vm.isLoading,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Estimation history"},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('estimation-test')]},proxy:true}])},[_c('v-blacker-table',{attrs:{"headers":_vm.estimationHistoryListHeaders,"items":_vm.estimationHistory},scopedSlots:_vm._u([{key:"orderId",fn:function(ref){
var orderId = ref.item.orderId;
return [(orderId)?_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
              name: 'order',
              params: { id: orderId },
            }}},[_vm._v(" "+_vm._s(orderId)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"created",fn:function(ref){
            var created = ref.item.created;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(created,'local', '-'))+" ")]}},{key:"errors",fn:function(ref){
            var errors = ref.item.errors;
return [_vm._v(" "+_vm._s((errors || []).join('; ') || '-')+" ")]}},{key:"invoice",fn:function(ref){
            var invoice = ref.item.invoice;
return [(invoice)?_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
              name: 'estimations',
              query: {
                pageNum: '1',
                searchId: invoice
              },
            }}},[_vm._v(" "+_vm._s(invoice)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"estimationTime",fn:function(ref){
            var estimationTime = ref.item.estimationTime;
return [_vm._v(" "+_vm._s(estimationTime || '-')+" ")]}},{key:"actions",fn:function(ref){
            var id = ref.item.id;
return [_c('v-btn',{attrs:{"to":{
              name: 'estimation',
              params: { id: id },
            },"icon":"","small":"","color":"info"}},[_c('v-icon',[_vm._v(" remove_red_eye ")])],1)]}}])}),_c('v-blacker-pagination',{attrs:{"items-count":_vm.pagedItemsCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }