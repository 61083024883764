import { mapState, mapActions, mapGetters } from 'vuex';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';

// @TODO implement after api will ready
// Now this component not used, because there is now backend api
// import EstimationRequest from '../../_components/estimationRequest';
import EstimationTest from '../../_components/EstimationTest.vue';

const dataLoadingOptions = { getterName: 'getEstimationHistory' };
const paginationOptions = { itemsName: 'estimationHistory' };
const searchOptions = {
  initialSearchDataStates: ['searchId', 'error', 'orderId'],
  customTypes: {
    error: 'number',
  },
};

const estimationHistoryListHeaders = Object.freeze([
  { text: 'ID', value: 'id' },
  { text: 'Created Date', value: 'created', width: '200px' },
  { text: 'Estimation Time', value: 'estimationTime', width: '200px' },
  { text: 'Invoice', value: 'invoice' },
  { text: 'Order ID', value: 'orderId' },
  { text: 'Error', value: 'errors' },
  { text: '', value: 'actions', width: '5%' },
]);

const estimationRequestResultListHeaders = Object.freeze([
  { text: 'Shipping Cost', value: 'shipCost' },
  { text: 'Total Days', value: 'totalDays' },
]);

export default {
  name: 'Estimation',

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  components: {
    EstimationTest,
  },

  data() {
    return {
      estimationHistoryListHeaders,
      estimationRequestResultListHeaders,
    };
  },

  computed: {
    ...mapState('estimation', ['estimationHistory']),

    ...mapState('enums', {
      errorsSelectOptions: ({ enums: { estimationErrors: items } }) => (items || [])
        .map(({ value, name: text }) => ({ value, text })),
    }),

    ...mapGetters('accounts', ['accountsOptions']),

    searchFieldsConfig() {
      return [
        {
          value: 'searchId',
          label: 'Search ID',
          type: 'text',
          flex: 'lg3',
        },
        {
          value: 'error',
          items: this.errorsSelectOptions,
          label: 'Error',
          type: 'select',
          flex: 'lg3',
        },
      ];
    },
  },

  created() {
    this.getAccounts({ activeOnly: true });
  },

  methods: {
    ...mapActions('estimation', ['getEstimationHistory']),
    ...mapActions('accounts', ['getAccounts']),

    onEstimationRequestSendSubmit() {
      this.onResetSearch();
      // this.pageNum = '1';
      // this.allowGettingData = true;
      setTimeout(() => {
        this.allowGettingData = true;
      }, 0);
    },
  },
};
